@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap");

@font-face {
  font-family: "reeyregular";
  src: url("../../public/assets/fonts/reey-regular-webfont.woff2") format("woff2"),
    url("../../public/assets/fonts/reey-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "casanova_serif_display_freeRg";
  src: url("../../public/assets/fonts/casanova_font_free-webfont.woff2") format("woff2"),
    url("../../public/assets/fonts/casanova_font_free-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import "./sass/variables";
@import "antd/dist/antd.css";
@import url("https://vjs.zencdn.net/7.20.1/video-js.css");
@import "./sass/spacing";
@import "./sass/sizing";
@import "./sass/typography";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: var(--white);
  font-family: var(--bs-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-text-color);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*Scrollbar CSS*/
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
  /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:vertical:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it*/
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
  /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:horizontal:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

.pos-rel {
  position: relative;
}

.g-d-flex {
  display: flex;
}

.g-align-items {
  align-items: center;
}

.list-none {
  list-style: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.p-spacerT {
  padding-top: 65px;

  @media (max-width: 767px) {
    padding-top: 40px;
  }
}

.p-spacerB {
  padding-bottom: 65px;

  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
}

.bg-black {
  background: var(--black);
}

.bg-light {
  background: #f9f9f9;
}

.bg-white {
  background: var(--white);
}

.text-uppercase {
  text-transform: uppercase;
}

.d-color {
  color: var(--bs-text-color);

  &:hover {
    color: var(--black);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.new-c-text-container {
  display: flex;
  justify-content: space-between;
}

.c-text-container {
  font-size: 1.125rem;
  // display: flex;
  // justify-content: space-between;

  ul,
  ol {
    text-align: left;
    margin: 0;
    padding: 0;
    padding-left: 20px;
    font-size: 1rem;
  }

  .heading-title {
    font-family: var(--bs-font-main-title-family);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    color: var(--bs-text-color);
    margin-bottom: 0px;
  }

  .heading-title-2 {
    font-style: normal;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: var(--white);

    @media (max-width:991px){
      font-size: 1.125rem;
    }
  }

  .banner-heading-title {
    font-size: 3.5rem;
    margin-bottom: 0px;

    &.white-color {
      color: var(--white);

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: var(--white);
        font-size: 4rem;
        margin-bottom: 0px;
      }
    }
  }

  .h-title {
    font-size: 3.25rem;
  }

  .h-title-2 {
    font-size: 1.875rem;
  }

  .h-title-3 {
    font-size: 1.625rem;
  }

  .h-title-4 {
    font-size: 1.25rem;
  }

  .h-title-5 {
    font-size: 1rem;
  }

  .h-title {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: 3.25rem;
      margin-bottom: 10px;
    }

    &.white-color {
      color: var(--white);

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: var(--white);
      }
    }
  }

  .h-title-2 {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: 2.8rem;
      margin-bottom: 10px;
    }
  }

  .h-title-3 {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 1.125rem;

    span {
      display: block;
    }
  }

  @media (max-width: 1200px) {

    font-size: 1rem;

    .banner-heading-title {
      font-size: 3rem;
      margin-bottom: 0px;

      &.white-color {
        color: var(--white);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          color: var(--white);
          font-size: 3rem;
          margin-bottom: 0px;
        }
      }
    }


    .h-title {
      font-size: 2.4rem;
    }

    .h-title-2 {
      font-size: 2rem;
    }

    .h-title-3 {
      font-size: 1.8rem;
    }

    .h-title-4 {
      font-size: 1rem;
    }

    .h-title-5 {
      font-size: 0.875rem;
    }

    p {
      font-size: 1rem;
    }


    .h-title {
      &.default-color {
        color: var(--bs-text-color);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          /* color: var(--bs-text-color); */
          font-size: 2.4rem;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 2.4rem;
      }
    }

    .h-title-2 {
      font-size: 1.625rem;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 1.625rem;
      }
    }

    .h-title-3 {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 1.8rem;
      }
    }

    .btn-primary {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 767px) {
    font-size: 1rem;

    .banner-heading-title {
      font-size: 2.6rem;
      margin-bottom: 0px;

      &.white-color {
        color: var(--white);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          color: var(--white);
          font-size: 2rem;
          margin-bottom: 0px;
        }
      }
    }


    .h-title {
      font-size: 2rem;
    }

    .h-title-2 {
      font-size: 1.375rem;
    }

    .h-title-3 {
      font-size: 1.125rem;
    }

    .h-title-4 {
      font-size: 1rem;
    }

    .h-title-5 {
      font-size: 0.875rem;
    }

    p {
      font-size: 1rem;
    }


    .h-title {
      &.default-color {
        color: var(--bs-text-color);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          color: var(--bs-text-color);
          font-size: 2.2rem
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 2.2rem;
      }
    }

    .h-title-2 {
      font-size: 1.375rem;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 1.6rem;
      }
    }

    .h-title-3 {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 1.8rem;
      }
    }

    .btn-primary {
      font-size: 0.875rem;
    }
  }
}

.btn-primary {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  min-width: 200px;
  font-weight: 700;
  height: auto;
  border: 1px solid var(--bs-text-color);
  background: var(--bs-text-color);
  color: var(--white);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--white);
    background: var(--black);
  }

  @media (max-width: 767px) {
    min-width: 140px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* #root {
  position: relative;
  height: 100%;
} */

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.hideElement {
  display: none !important;
}

.App {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* .navbar_toggle {
  overflow: hidden;
  
} */

.mc_row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}


.mc_row_new {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.mc_row_new>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.content-container,
.mc_container,
.mc_container_fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mc_container {
  max-width: calc(100% - 100px);
}

.mc_container_inner {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mc_container_inner {
  max-width: 1170px;
}

.mc_col_full,
.mc_col_fixed,
.mc_col_fluid,
.mc_l_o_c {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.mc_col_full {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.spc_between {
  justify-content: space-between;
  align-items: center;
}

/* ======= */
.loc_popup_o_cb {
  background: rgba(0, 0, 0, 0.3);
  color: var(--white);
  position: fixed;
  width: 100%;
  height: 100%;
  left: -100%;
  top: 0px;
  z-index: 98;
  font-size: 1.125rem;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;

  &.close_popup {
    left: 0px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }

  .loc_close_container {
    position: absolute;
    width: 35px;
    height: 35px;
    padding: 5px;
    background-size: 70%;
    display: block;
    top: 20px;
    cursor: pointer;
    margin-left: 20px;
    z-index: 99;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 100%;

    .loc_close {
      position: absolute;
      width: 25px;
      height: 25px;
      background: url(../../public/assets/images/close-icon.svg) no-repeat center;
      //   background-size: 70%;
      //   display: block;
      text-indent: -99999px;
      //   top: 20px;
      //   cursor: pointer;
      //   margin-left: 20px;
      //   z-index: 99;
    }
  }
}

.loc_popup_i_cb {
  width: 100%;
  height: 100%;
  max-width: 500px;
  background: #000;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d8d8d8 rgba(216, 216, 216, 0.4);
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgba(216, 216, 216, 0.4);
  }
}

.loc_popup_content_cb {
  position: relative;
  width: 100%;
  padding: 50px 30px 15px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    position: relative;
    margin-bottom: 20px;
  }

  a,
  span {
    color: var(--white);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    position: relative;
    padding-left: 34px;
    display: block;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 24px;
      height: 30px;
      background-position: center !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .location_icon {
    span:before {
      background: url(../../public/assets/images/location-icon.svg);
    }

    a {
      padding-left: 0;
    }
  }

  .telephone_icon {
    a:before {
      background: url(../../public/assets/images/telephone-icon.svg);
    }
  }

  .mail_icon {
    a:before {
      background: url(../../public/assets/images/mail-icon.svg);
    }
  }
}

.full-image {
  width: 100% !important;
  display: block;
  height: auto !important;
}

/* ======= */
.notfound_outer_container {
  width: 100%;
  min-height: 400px;
  height: 100vh;
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  background: var(--black);
  display: flex;
  flex-wrap: wrap;

  @media(max-width:767px) {
    height: auto;
  }
}

.notfound {
  min-height: 400px;
  height: 100%;
  padding-top: 40px;
  width: 100%;
  max-width: 100% !important;
  position: relative;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  background: url(../../public/assets/images/notfound.jpg) no-repeat center;
  background-size: cover;
  padding-bottom: 40px;


  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
}

.nf_i_cb {
  text-align: center;
  font-size: 1rem;
  color: var(--white);
  position: relative;
  z-index: 1;

  h1 {
    font-size: 5rem;
    font-family: var(--bs-font-main-title-family);
    color: var(--white);
    line-height: 1;
    margin: 0;
  }

  h3 {
    font-size: 1.5rem;
    color: var(--white);
    margin: 0;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 25px;
  }

  a {
    display: inline-flex;
    color: var(--white);
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 25px;
    border: 1px solid var(--white);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--white);
      opacity: 0.5;
    }
  }
}

/* .scroll_cb,
body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d8d8d8 rgba(216, 216, 216, 0.4);
  scrollbar-width: thin;
  height: 98%;

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgba(216, 216, 216, 0.4);
    display: block;
  }
} */

/* body {
  height: 100%;
} */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  margin-left: 10px;
}

.loading_text {
  font-size: 2rem;
  font-family: var(--bs-font-main-title-family);
  color: var(--white);
  margin: 0;
}

.l_m_cb {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .l_i_cb {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
}

.ty_o_cb {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.ty_cb {
  position: relative;
  text-align: center;
  font-size: 1rem;

  span {
    display: block;
    width: 55px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    img {
      max-width: 100%;
    }
  }

  h4 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
  }
}

.mt5 {
  margin-top: 5px;
}

.error {
  font-size: 12px;
  color: #f53232;
}

.backPage {
  position: absolute;
  color: var(--white);
  font-size: 1rem;
  bottom: 20px;
  left: 75px;
  z-index: 90;
  display: inline-flex;
  cursor: pointer;
  user-select: none;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    background: url(../../public/assets/images/right-slider-arrow.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 6px;
    left: -16px;
  }
}

.availability_btn,
.book_online_btn {
  position: fixed;
  color: var(--white);
  font-size: 1rem;
  bottom: 25px;
  right: 75px;
  // z-index: 90;
  display: inline-flex;
  cursor: pointer;
  user-select: none;

  .btn_link {
    width: 145px;
    height: 62px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: url(../../public/assets/images/line-btn-bg.svg) no-repeat center;
    background-size: contain;
    text-transform: uppercase;
    font-weight: 600;
    // animation: shake 0.5s;
    // -webkit-animation: shake 0.5s;
    animation: fadeInFadeOut 2s ease-in-out infinite;
    -webkit-animation: fadeInFadeOut 2s ease-in-out infinite;
    animation-iteration-count: infinite;
    color: var(--white);
    text-decoration: none;

    &:hover {
      color: var(--white);
    }

    &.activebtn_animation {
      animation: shake 0.5s;
      -webkit-animation: shake 0.5s;
      animation-iteration-count: infinite;
    }
  }
}

.availability_btn {
  bottom: 102px;
}

@-webkit-keyframes fadeInFadeOut {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes fadeInFadeOut {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
    -moz-transform: translateX(-6px) rotateY(-9deg);
    -ms-transform: translateX(-6px) rotateY(-9deg);
    -o-transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
    -moz-transform: translateX(5px) rotateY(7deg);
    -ms-transform: translateX(5px) rotateY(7deg);
    -o-transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
    -moz-transform: translateX(-3px) rotateY(-5deg);
    -ms-transform: translateX(-3px) rotateY(-5deg);
    -o-transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
    -moz-transform: translateX(2px) rotateY(3deg);
    -ms-transform: translateX(2px) rotateY(3deg);
    -o-transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
    -moz-transform: translateX(-6px) rotateY(-9deg);
    -ms-transform: translateX(-6px) rotateY(-9deg);
    -o-transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
    -moz-transform: translateX(5px) rotateY(7deg);
    -ms-transform: translateX(5px) rotateY(7deg);
    -o-transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
    -moz-transform: translateX(-3px) rotateY(-5deg);
    -ms-transform: translateX(-3px) rotateY(-5deg);
    -o-transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
    -moz-transform: translateX(2px) rotateY(3deg);
    -ms-transform: translateX(2px) rotateY(3deg);
    -o-transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .mc_container {
    max-width: calc(100% - 20px);
  }
}

@media (max-width: 1100px) {
  .mc_container {
    max-width: calc(100% - 20px);
  }

  .book_online_btn {
    right: 15px;
  }
}

@media (max-width: 767px) {
  .mc_container {
    max-width: calc(100% - 0px);
  }

  .book_online_btn {
    width: 100%;
    bottom: 0px;
    padding: 10px 15px;
    text-align: center;
    background: -moz-linear-gradient(top,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.68) 99%,
        rgba(0, 0, 0, 0.68) 100%);
    background: -webkit-linear-gradient(top,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.68) 99%,
        rgba(0, 0, 0, 0.68) 100%);
    background: linear-gradient(to bottom,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.68) 99%,
        rgba(0, 0, 0, 0.68) 100%);
    left: 0;
    display: none;

    .btn_link {
      margin-left: auto;
      margin-right: auto;
      font-size: 14px;
      width: 180px;
      height: 48px;
      background-size: 70% auto;
    }
  }

  .btn_mob_bo {
    display: inline-block;

    .book_online_btn {
      display: inline-block;
      position: relative;
      bottom: inherit;
      right: inherit;
      left: inherit;
      padding: 0px;
      background: transparent;
      z-index: inherit;

      span {
        color: var(--white);
        font-size: 11px;
        display: block;
        padding: 8px 8px;
        background: transparent;
        border: 1px solid var(--white);
        width: auto;
        height: auto;
        animation: inherit;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1100px) {}

@media (max-width: 480px) {
  .backPage {
    font-size: 0.875rem;
    bottom: 8px;
  }

  .backPage:before {
    top: 5px;
  }
}

.slider_thumb_height {
  min-height: 55px;

  img {
    position: relative;
    z-index: 2;
  }

  .loading-spinner {
    width: 24px;
    height: 24px;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border: 2px solid #f3f3f3;
  }
}

.set_loading_cb {
  .slider_full_cb {
    z-index: 2;
  }
}

.loading_cb {
  text-align: center;
  position: relative;
  background: rgba(56, 54, 54, 0.7);

  .loading-spinner {
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: initial;

    /* width: 24px;
height: 24px;
border-top: 2px solid rgba(255, 255, 255, .2);
border: 2px solid #f3f3f3; */
  }
}

.set_h_bh,
.set_loading_cb {
  min-height: 600px;

  .loader_cb {
    z-index: 1;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading_text_data {
      font-size: 1.5rem;
      font-family: var(--bs-font-main-title-family);
      color: var(--white);
      margin: 0;
    }

    .loading-spinner {
      position: static;
      margin: 0px;
      margin-left: 10px;
      width: 32px;
      height: 32px;
      z-index: 1;
    }
  }

  img {
    position: relative;
    z-index: 9;
  }
}

.set_h_bh {
  .loading_text_data {
    position: relative;
    display: flex;
    align-items: center;

    &:after {
      content: "";
      border: 3px solid #f3f3f3;
      border-top: 3px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      position: relative;
      margin: 0px;
      margin-left: 10px;
      width: 32px;
      height: 32px;
      display: block;
      -webkit-animation: spinner 1.5s linear infinite;
      animation: spinner 1.5s linear infinite;
    }
  }
}

@media (max-width: 1400px) {
  .set_h_bh {
    min-height: 500px;
  }
}

@media (min-width: 1101px) and (max-width: 1399px) {
  .set_h_bh {
    min-height: 400px;
  }
}

@media (max-width: 767px) {
  .set_h_bh {
    min-height: 350px;
  }
}

@media (max-width: 424px) {
  .set_h_bh {
    min-height: 240px;
  }

  .add_mb_spacer {
    margin-bottom: 15px !important;
  }
}

.set_height_t_i_cb {
  background: rgba(56, 54, 54, 0.7);
  min-height: 200px;
}

.set_height_l_l_cb {
  background: rgba(56, 54, 54, 0.7);
  min-height: 250px;
  height: 100%;
  position: relative;
}

@media (max-width: 767px) {
  .set_height_t_i_cb {
    min-height: 1px;

    .loader_cb {
      display: none;
    }
  }

  /* .set_h_bh .loader_cb,  */
  .set_loading_cb .loader_cb {
    z-index: initial;
    top: 200px;
    left: 0;
    bottom: auto;
    right: 0;
  }

  .set_h_bh {
    .loader_cb {
      z-index: initial;

      .loading_text_data {
        z-index: -1;
      }
    }
  }
}

.CookieConsent {
  font-size: 1rem;
  background: #000 !important;
  max-width: 320px;
  width: 100%;
  z-index: 9999999992 !important;
}

.CookieConsent>div:first-child {
  margin: 0px !important;
  padding: 15px;
  padding-bottom: 0px !important;
}

.cookie_btn_style {
  font-weight: 700 !important;
  font-size: 1rem !important;
  padding: 5px 20px !important;
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}

.mi_btn {
  text-decoration: underline;
  color: rgb(255, 212, 45);
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    color: rgb(255, 212, 45);
  }
}

.cp_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  .cp_l_c,
  .cp_r_c {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
  }

  .cp_l_c {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cp_r_c {
    background: #000;
    color: var(--white);
  }

  .cp_s_w_cb {
    font-size: 1rem;
    padding-top: 150px;
    padding-left: 50px;
    padding-right: 0px;
    padding-bottom: 30px;
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

.outer_cookie_cb {
  position: relative;
  overflow-x: auto;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d8d8d8 rgba(216, 216, 216, 0.4);
  scrollbar-width: thin;
  height: 100%;

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgba(216, 216, 216, 0.4);
    display: block;
  }
}

.cookie_cb {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;

  .c_title {
    font-family: var(--bs-font-main-title-family);
    color: var(--white);
    font-size: 3.5rem;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .cookie_cb {
    .c_title {
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
  }

  .cp_container {

    .cp_l_c,
    .cp_r_c {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .cp_r_c {
      background: transparent;
      color: var(--white);

      &:before {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        content: "";
      }
    }

    .cp_l_c {
      display: none;
    }

    .cp_s_w_cb {
      padding-top: 122px;
      padding-bottom: 0px;
      height: 100%;
      padding-left: 0;
    }
  }
}

.bw_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  .bw_l_c,
  .bw_r_c {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
  }

  .bw_l_c {
    flex: 1 0 0%;
    max-width: 100%;
  }

  .bw_l_c {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bw_r_c {
    background: #000;
    color: var(--white);
    flex: 0 0 550px;
    max-width: 100%;
  }

  .bw_s_w_cb {
    font-size: 1rem;
    padding-top: 122px;
    padding-left: 50px;
    padding-right: 0px;
    padding-bottom: 30px;
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

.outer_bw_cb {
  position: relative;
  overflow-x: auto;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d8d8d8 rgba(216, 216, 216, 0.4);
  scrollbar-width: thin;
  height: 100%;

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgba(216, 216, 216, 0.4);
    display: block;
  }
}

.bw_cb {
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 767px) {

  body,
  html {
    font-size: 16px;
  }

  .bw_container {

    .bw_l_c,
    .bw_r_c {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .bw_r_c {
      background: transparent;
      color: var(--white);

      &:before {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        content: "";
      }
    }

    .bw_l_c {
      display: none;
    }

    .bw_s_w_cb {
      padding-top: 122px;
      padding-bottom: 0px;
      height: 100%;
      padding-left: 0;
    }
  }
}

.full_width_cb {
  width: 100%;
}

/* code for book a visit */

.tourFormcode,
.select_faq {
  .no-icon-prefix-input {
    .ant-picker-suffix {
      display: none;
    }

    input {
      &::placeholder {
        color: var(--bs-text-color);
      }
    }
  }

  .no-icon-prefix {
    padding-left: 0px !important;

    .ant-select-selector {
      border: 0px !important;
      box-shadow: none !important;

      &:focus {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }

    .ant-select-selection-search {
      padding-left: 10px;
    }

    .ant-select-selection-item {
      color: var(--bs-text-color);
    }

    .ant-select-arrow {
      display: none;
    }
  }

  .ant-select-selection-placeholder {
    color: var(--bs-text-color);
  }

  .ant-picker-clear,
  .ant-picker-clear:hover {
    color: var(--white);
    background: transparent;
  }

  .ant-picker-panel-container .ant-picker-panel {
    border-width: 0 0 0 0;
  }

  .ant-picker-panel-container {
    background: #000;
    border: 1px solid var(--white);
    margin-top: -5px;
  }

  .ant-picker-cell {
    color: rgba(255, 255, 255, 0.25);
  }

  .ant-picker-header {
    border-bottom: 0px;
  }

  .ant-picker-panel .ant-picker-footer {
    // border-top: 0px;
  }

  .ant-picker-dropdown,
  .ant-picker-header,
  .ant-picker-header button,
  .ant-picker-header>button:hover,
  .ant-picker-header>button:hover,
  .ant-picker-header-view button:hover,
  .ant-picker-today-btn,
  .ant-picker-content th,
  .ant-picker-cell-in-view {
    color: var(--white);
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--white);
    color: #000;
  }

  .ant-picker-cell-disabled {
    color: rgba(255, 255, 255, 0.25);
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: var(--white);
    color: #000;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    border: 1px solid #323232;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 0px solid var(--white);
  }

  .ant-picker-time-panel {
    width: 120px;
    min-width: auto;
  }

  .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    display: block;
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0 0 0 14px;
    color: var(--white);
    line-height: 28px;
    border-radius: 0;
    cursor: pointer;
    transition: background 0.3s;
  }

  .ant-picker-ok {

    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      color: #f5f5f5;
      border-color: transparent;
      background: transparent;
      text-shadow: none;
      box-shadow: none;
    }

    .ant-btn-primary {
      color: var(--white);
      border-color: transparent;
      background: transparent;
    }
  }

  .ant-picker-time-panel-column {
    li.ant-picker-time-panel-cell {
      .ant-picker-time-panel-cell-inner:hover {
        background: #f5f5f5;
        color: #000;
      }
    }

    li.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background: #f5f5f5;
        color: #000;
      }
    }

    li.ant-picker-time-panel-cell-disabled {

      .ant-picker-time-panel-cell-inner,
      .ant-picker-time-panel-cell-inner:hover {
        color: rgba(0, 0, 0, 0.2);
        background: #f5f5f5;
      }
    }
  }

  .ant-picker-input>input {
    font-size: 1rem;
    color: var(--bs-text-color);
  }
}

.no_header .mc_ps_cb {
  display: none;
}

@media (max-width: 767px) {
  .no_header {
    height: 100%;

    .slick-slider,
    .slick-list {
      height: 100%;
      position: relative;
    }

    .slick-track {
      display: flex !important;
      height: 100%;
    }

    .slick-slide {
      height: inherit !important;
    }

    .slick-slide>div {
      height: 100%;
      position: relative;
    }
  }
}

/* office listing code page */
.office_listing_style {
  .slick-slide.slick-active {
    position: relative;

    .nav_i_cb_inner {
      position: relative;
      cursor: pointer;
      display: flex;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
      }
    }

    &:hover {
      .nav_i_cb_inner {
        &::before {
          background: rgba(255, 255, 255, 0);
        }
      }
    }
  }

  .slick-slide.slick-active.slick-current {
    .nav_i_cb_inner {
      &::before {
        background: rgba(255, 255, 255, 0);
      }
    }
  }

  .slick-arrow {
    &.slick-prev {
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: url(../../public/assets/images/right-slider-arrow.svg) no-repeat center;
        background-size: auto;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.slick-next {
      &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: url(../../public/assets/images/left-slider-arrow.svg) no-repeat center;
        background-size: auto;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .backPage {
    bottom: 10px;
    left: 30px;
  }
}

.vjs-controls-disabled .vjs-control-bar,
.vjs-using-native-controls .vjs-control-bar,
.vjs-big-play-button,
.vjs-control-bar,
.vjs-error .vjs-control-bar {
  display: none !important;
}

.video-js {
  width: 100% !important;
  height: 100% !important;
}

.btn_cb_ob {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.km_btn {
  display: inline-flex;
  padding: 10px 5px;
  align-items: center;
  text-transform: uppercase;
  color: var(--white);
  bottom: 20px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  margin-right: 0px;
  cursor: pointer;
  font-size: 0.875rem;


  &:hover {
    opacity: 0.5;
  }
}

.c_b_overlay {
  position: fixed;
  right: -200%;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;

  &.c_b_active {
    right: 0px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }

  .c_b_inner {
    background: #000000;
    max-width: 500px;
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #d8d8d8 rgba(216, 216, 216, 0.4);
    scrollbar-width: thin;

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: rgba(216, 216, 216, 0.4);
    }

    .c_b_close {
      position: absolute;
      width: 30px;
      height: 30px;
      background: url(../../public/assets/images/close-icon.svg) no-repeat center;
      background-size: 70%;
      display: block;
      text-indent: -99999px;
      top: 20px;
      right: 20px;
      cursor: pointer;
      margin-right: 10px;
      z-index: 3;
    }
  }

  .content-container {
    height: calc(100% - 70px) !important;
    max-width: 500px;
    padding: 0 30px 10px;
    overflow-y: auto;

    position: fixed;
    bottom: 0;

    color: var(--white);
    font-size: 1rem;

    p {
      white-space: pre-line;
      margin-bottom: 0;
    }

    h1,
    h2,
    h3 {
      color: var(--white);
      font-weight: 700;
      margin-bottom: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: rgba(216, 216, 216, 0.4);
    }
  }

  .c_b_i_cb {
    position: relative;
    padding: 50px 25px 70px;
    width: 100%;
    color: var(--white);
    font-size: 1rem;

    p {
      white-space: pre-line;
      margin-bottom: 15px;
    }

    h3,
    h4,
    h5 {
      margin-bottom: 15px;
      color: var(--white);
      font-weight: 700;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    h5 {
      font-size: 1.125rem;
    }
  }
}

.form-field-select-o-cb {
  background: transparent;
  width: 100%;
  border: 1px solid var(--light);
  color: var(--light);
  font-size: 1rem;
  font-weight: 200;
  min-height: 50px;
  padding: 0px !important;

  .ant-select-selector {
    padding: 8px 20px !important;
    display: block;
    border: 0px !important;
    height: 50px;
  }

  .ant-select-focused .ant-select-selector {
    border-right-width: 0px !important;
  }

  .ant-select-selection-placeholder {
    color: var(--light);
  }
}

.mainForm {
  .customdd {
    color: var(--white) !important;

    .ant-select-selector {
      background: none;
      border: none;
      padding: 0px;
      border: 0px !important;
      box-shadow: none !important;
      height: 50px;
    }

    .ant-select-focused {
      box-shadow: none !important;
      border-color: none !important;
    }

    .ant-select-selection-item {
      /* line-height: 1; */
      padding: 0;
      /* height: auto; */
    }

    .ant-select-selector .ant-select-selection-search-input {
      /* height: auto; */
    }

    .ant-select-selection-item:after {
      display: none;
    }
  }
}

.containerDropdownCommon.ant-select-dropdown {
  // background-color: rgba(0, 0, 0, 0.85);
  background-color: rgba(0, 0, 0, 1);
  color: #fbfafb;
  /*   left:15px !important; */
  border: 1px solid #fbfafb;

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(250, 250, 250, 1);
    color: #000000;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 0, 0, 0.85);
    color: #fbfafb;
  }

  .ant-select-item {
    color: #fbfafb;
    padding: 6px 12px;
  }
}

.spacerCB_loading .loader_cb {
  padding: 15px;
}

@media (max-width: 767px) {
  .btn_cb_ob {
    justify-content: center;
  }

  .CookieConsent {
    max-width: 100%;

    div {
      width: 100% !important;
      text-align: center;
    }
  }

  .CookieConsent,
  .cookie_btn_style {
    font-size: 14px !important;
  }

  .spacerCB_loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spacerCB_loading .loader_cb {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: 0;
  }

  .spacerCB_loading .loading_text_data {
    font-size: 1.125rem !important;
    margin-bottom: 15px !important;
  }

  .positionfixed {
    position: fixed;
    width: 100%;
  }

  /* .centerPicker{
    &.ant-picker-dropdown, &.ant-select-dropdown {
    top: 98% !important;
    left: 50% !important;
    position:fixed;
    transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    -o-transform: translate(-50%, -100%);
  }
} */
}

@media (max-width: 424px) {
  .km_btn {
    font-size: 0.75rem !important;
  }

  .book_online_btn .btn_link {
    font-size: 12px;
  }

  .link_menu_cb.v_mob_cb li:last-child {
    margin-bottom: 0px;
  }

  .loc_popup_content_cb {
    font-size: 14px;
  }

  .loc_popup_content_cb a::before,
  .loc_popup_content_cb span::before {
    content: "";
    top: 2px;
    left: 0px;
    width: 16px;
    height: 16px;
  }

  .loc_popup_content_cb a,
  .loc_popup_content_cb span {
    padding-left: 24px;
  }

  .form-field-select-o-cb .ant-select-selector {
    font-size: 0.875rem;
    padding: 7px 10px !important;
  }

  .mainForm .customdd .ant-select-selector {
    height: 50px;
  }
}

// content container style start
.content-container {
  width: 100%;
  position: fixed;
  bottom: 0;

  overflow-y: auto;
}

@media only screen and (min-width: 1365px) {
  .setHeightDesktop {
    width: 100%;
    /* height:100%; */
  }
}

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
  .content-container {
    height: calc(100% - 100px);
  }
}

/* Medium devices (landscape tablets, 576px and up) */
@media only screen and (min-width: 576px) {
  .content-container {
    height: calc(100% - 115px);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .content-container {
    height: calc(100vh - 115px);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .content-container {
    height: calc(100vh - 100px);
  }
}

/* Extra Extra large devices (large desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .content-container {
    height: calc(100vh - 115px);
  }
}

// content container style end

.ant-image-preview-root {
  .ant-image-preview-mask {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

// antd calender style start
.custom-calender-conteiner {
  * {
    font-size: 1rem;
  }

  .ant-picker-panel-container {
    background: rgba(0, 0, 0, 0.85);
    border: 0;

    .ant-picker-header button,
    .ant-picker-content th {
      color: var(--white);
    }

    .ant-picker-header>button,
    .ant-picker-cell-inner {
      border: 1px solid var(--white);
    }

    .ant-picker-cell-disabled {
      color: hsla(0, 0%, 100%, 0.25);
    }

    .ant-picker-date-panel {
      width: 380px;

      .ant-picker-body {
        padding: 5px 14px 14px;
      }

      .ant-picker-content {
        width: 352px;
      }
    }

    .ant-picker-panel {
      border-width: 0;

      .ant-picker-header {
        padding: 14px 14px 0;
        border: 0;

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn {
          display: none;
        }

        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn {
          height: 30px;
          min-width: 30px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-picker-body {
        .ant-picker-cell-inner {
          height: 36px;
          line-height: 36px;
          min-width: 36px;

          border: 1px solid var(--white);
          border-radius: 0;
          color: var(--white);
        }

        .ant-picker-cell-disabled {
          .ant-picker-cell-inner {
            border: 1px solid hsla(0, 0%, 100%, 0.25);
            color: hsla(0, 0%, 100%, 0.25);
          }
        }

        .ant-picker-cell-in-view.ant-picker-cell-today {
          .ant-picker-cell-inner:before {
            border: 0;
          }
        }

        .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
        .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
        .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
          color: #000;
          background-color: var(--white);
        }

        .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
        .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
          color: #000;
        }
      }
    }

    @media (max-width: 1399px) {
      * {
        font-size: 0.75rem !important;
      }

      .ant-picker-header {
        padding-top: 7px !important;
      }

      .ant-picker-cell {
        .ant-picker-cell-inner {
          min-width: 24px !important;
          height: 24px !important;
          line-height: 24px !important;
        }
      }

      .ant-picker-header>button {
        min-width: 24px !important;
        height: 24px !important;
      }

      .ant-picker-date-panel {
        width: 280px !important;

        .ant-picker-body {
          padding: 0px 4px 5px !important;
        }

        .ant-picker-content {
          width: 270px !important;

          th {
            width: 30px !important;
          }
        }
      }
    }

    @media (max-width: 767px) {
      .ant-picker-header {
        padding: 10px 10px 0px !important;
      }

      .ant-picker-date-panel {
        width: 280px !important;

        .ant-picker-content {
          width: 270px !important;
        }
      }
    }
  }
}

// antd calender style end

// antd time picker style end
.custom-time-picker-container {
  .ant-picker-panel-container {
    color: var(--white);
    background: rgba(0, 0, 0, 0.85);

    .ant-picker-panel {
      border: 0;
    }

    .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
      color: var(--white);
    }

    .ant-picker-time-panel-column li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover,
    .ant-picker-time-panel-column li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      color: #000;
      background: var(--white);
    }
  }

  .ant-picker-footer {
    display: none;
  }
}

// antd time picker style end

// antd select dropdown start
.custom-select-dropdown {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fbfafb;

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(250, 250, 250, 1);
    color: #000000;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 0, 0, 0.85);
    color: #fbfafb;
  }

  .ant-select-item {
    color: #fbfafb;
  }
}

// antd select dropdown end

// antd custom input start
.custom-input {
  width: 100%;
  background: transparent;

  color: var(--white);
  border: 1px solid var(--white);

  font-weight: 200;

  .ant-input {
    background: transparent;
    color: var(--white);

    &::placeholder {
      color: var(--white);
    }
  }

  &:hover,
  &:focus {
    border-color: var(--white) !important;
    box-shadow: none;
  }
}

// antd custom input end

// antd custom select start
.custom-select {
  width: 100%;
  color: var(--white);

  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: var(--white);
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: 1px solid var(--white) !important;
  }
}

// antd custom select end

@media (max-width: 359px) {
  .h_left_section {
    width: 150px;
  }

  .blink-logo {
    height: 36px;
  }

  .location_cb p {
    font-size: 10px !important;
  }
}

.sticky-btn-dark {
  color: var(--white);
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  left: 0;
  z-index: 80;
  padding-right: 20px;

  a,
  a:hover {
    display: inline-flex;
    padding: 10px 35px;
    background: #181818;
    margin-left: 0.5rem;
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 1rem;
    color: var(--white);
  }
}

.sticky-btn-light {
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;

  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 80;

  a,
  a:hover {
    display: inline-flex;
    padding: 10px 35px;
    background: var(--white);
    margin-left: 0.5rem;

    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    font-size: 1rem;
    color: #181818;
  }
}

.scroll-container {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d8d8d8 rgba(216, 216, 216, 0.4);
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  &::-webkit-scrollbar {
    width: var(--spacing-xxs);
    height: var(--spacing-xxs);
    background-color: rgba(216, 216, 216, 0.4);
  }
}

.the-tallys-winner {
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 50px;


  img {
    object-fit: contain;
  }

  @media (max-width:1400px) {
    width: 150px;
  }

  @media (max-width:991px) {
    top: 20px;
    left: 20px;
  }

  @media (max-width: 767px) {
    position: absolute;
    top: 10px;
    left: auto;
    text-align: end;
    margin-bottom: 15px;
    right: 0;
    width: 100px;
  }
}

/* ray code start */
.setBanner {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: var(--black);
}

.bannerInner {
  position: relative;
  width: 100%;
}

.slider-cb-main {
  .slick-dots {
    left: 0px;
    bottom: 0px;
  }

  .slick-arrow {
    &.slick-disabled {
      opacity: 0.2;
    }
  }

  .slick-prev,
  .slick-next {
    width: 36px;
    height: 36px;
    background-size: auto 60% !important;
  }

  .slick-prev,
  .slick-prev:hover,
  .slick-prev:focus {
    left: 0px;
    background: url(../../public/assets/images/slider-left-arrow.svg) no-repeat center;

    &:before {
      display: none;
    }
  }

  .slick-next,
  .slick-next:hover,
  .slick-next:focus {
    right: 0px;
    background: url(../../public/assets/images/slider-right-arrow.svg) no-repeat center;

    &:before {
      display: none;
    }
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: var(--bs-text-color);
  }

  .slick-slide>div {
    position: relative;
    height: 100%;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
}

.slider-thumb {
  .slick-slider {
    max-width: 1000px;
    margin-left: auto;

    @media(max-width:991px) {
      max-width: 680px;
    }

    @media(max-width:767px) {
      max-width: 380px;
    }
  }

  * {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .thumb-m-inner-cb {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  .slick-current {
    .thumb-m-inner-cb {
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      width: 180px;
      height: 180px;
    }
  }

  @media (max-width: 991px) {
    .slick-current {
      .thumb-m-inner-cb {
        width: 120px;
        height: 120px;
      }
    }
  }

  @media (max-width: 767px) {
    .slick-current {
      .thumb-m-inner-cb {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.btn_more_link_cb {
  a {
    display: inline-flex;
    position: relative;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    cursor: pointer;

    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;
    }
  }

  &:hover a {
    i {
      display: block;
      margin-bottom: 2px;
      margin-left: 8px;

      img {
        animation: headShake 3s linear infinite;
        -webkit-animation: headShake 3s linear infinite;
      }
    }
  }
}

.content-outer-container,
.benefit-outer-container {
  .c-text-container {
    /* max-width: 800px; */
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .heading-title {
      /* margin-bottom: 15px; */
    }

    .btn-common {
      padding-top: 10px;
    }
  }

  .image-container {
    margin-top: 30px;
  }
}

.benefit-outer-container {
  .slider-img-cb {
    margin-top: 30px;

    .slider-t-o-cb {
      flex: 0 0 auto;
      width: 25%;
      text-align: center;
      font-size: 1rem;
      padding-left: 15px;
      padding-right: 15px;
      /* @media(max-width:767px){
        font-size: 0.813rem;
      } */
    }

    .slider-t-i-img-cb {
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 15px;
      width: 200px;
      height: 200px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }
  }
}

/* service page banner code */
.header-container-outer-box {
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  background: var(--black);
  display: flex;
  flex-wrap: wrap;
}

.fullHeightSlider {
  position: relative;
  height: 100%;

  .slider_full_cb {
    position: relative;
    height: 100%;
  }

  .slick-list {
    height: inherit !important;
  }

  .slick-track {
    display: flex !important;
    height: inherit !important;
  }

  .slick-slide {
    height: inherit !important;

    >div {
      height: inherit;
    }
  }
}

/* padding-top: 56.25%; */
@media (max-width: 1024px) {
  .header-container-outer-box {
    min-height: 1px;
  }

  .mobile-design-cb {
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    .inner-m-cb {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
    }
  }
}

@media (max-width: 767px) {
  .fullHeightSlider {
    .slider_full_cb {
      height: auto;
    }
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
    -moz-transform: translateX(-6px) rotateY(-9deg);
    -ms-transform: translateX(-6px) rotateY(-9deg);
    -o-transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
    -moz-transform: translateX(5px) rotateY(7deg);
    -ms-transform: translateX(5px) rotateY(7deg);
    -o-transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
    -moz-transform: translateX(-3px) rotateY(-5deg);
    -ms-transform: translateX(-3px) rotateY(-5deg);
    -o-transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
    -moz-transform: translateX(2px) rotateY(3deg);
    -ms-transform: translateX(2px) rotateY(3deg);
    -o-transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

.mc_query_cb {
  margin-top: 15px
}


@media(max-width:767px) {
  .mob-text-center {
    text-align: center;
  }
}



/* booking online button */

.btn_booking-online {
  position: absolute;
  bottom: 0px;
  right: 20px;
  z-index: 10;

  .book_online_btn {
    position: relative;
    right: auto;
  }
}

@media(min-width:768px) {
  .hide-desktop {
    display: none !important;
  }
}

@media(max-width:767px) {
  .hide-mobile {
    display: none !important;
  }
}

.mc_container_inner {
  .ant-input-affix-wrapper {
    border-color: rgba(55, 55, 55, 0.4);
    box-shadow: none
  }

  .ant-picker-focused {
    box-shadow: none;
  }
}

.nowrapHeading>* {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ribbonContainer {
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: center;
  bottom: -9px;

  .ribbon {
    width: 400px;
    height: 60px;
    position: relative;
    text-align: center;
    background: #FFC328;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 900;
    color: #000000;
  }

  .ribbon i {
    position: absolute;
  }

  .ribbon i:first-child,
  .ribbon i:nth-child(2) {
    position: absolute;
    left: -20px;
    bottom: -20px;
    z-index: -1;
    border: 20px solid transparent;
    border-right-color: #043140;
  }

  .ribbon i:nth-child(2) {
    left: auto;
    right: -20px;
    border-right-color: transparent;
    border-left-color: #043140;
  }

  .ribbon i:nth-child(3),
  .ribbon i:last-child {
    width: 20px;
    bottom: -20px;
    left: -40px;
    z-index: -2;
    border: 30px solid #FFBF1A;
    border-left-color: transparent;
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }

  .ribbon i:last-child {
    bottom: -20px;
    left: auto;
    right: -40px;
    border: 30px solid #FFBF1A;
    border-right-color: transparent;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  @media(max-width:767px) {
    bottom: 0px;


    .ribbon {
      width: 300px;
      height: 40px;
      font-size: 1.125rem;
    }

    .ribbon i:nth-child(3),
    .ribbon i:last-child {
      width: 20px;
      bottom: -20px;
      left: -20px;
      z-index: -2;
      border: 20px solid #FFBF1A;
      border-left-color: transparent;
      transform-origin: 100% 0;
    }

    .ribbon i:last-child {
      bottom: -20px;
      left: auto;
      right: -20px;
      border: 20px solid #FFBF1A;
      border-right-color: transparent;
      transform-origin: 0 0;
    }
  }
}


.loadingData {
  background: var(--white);
  min-height: 400px;

  .loading_text_data {
    color: var(--bs-text-color) !important;
  }
}

.readMoreContent {

  color: var(--white);
  padding-left: 0px !important;

  &:hover {
    color: var(--white);
  }

  @media(max-width:767px) {

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px !important;
    min-width: 200px;
    font-weight: 700;
    height: auto;
    border: 1px solid var(--bs-text-color);
    background: var(--bs-text-color);
    color: var(--white);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    font-size: 0.875rem;
    min-width: 140px;
    margin-bottom: 20px;


    i {
      display: none !important;
    }

    &:hover {
      color: var(--white);
      background: var(--black);
    }
  }
}


.accordion-container {
  .sm-accordian-detail {
    display: none;
  }

  .accordion-title {
    border: 1px solid rgba(55, 55, 55, 0.4);
    padding: 10px 15px !important;
    font-size: 1.125rem;
    font-weight: 900;
    cursor: pointer;
    display: none;
    position: relative;

    &:after {
      background: url(../../public/assets/images/plus-icon.svg) center;
      position: absolute;
      content: "";
      width: 14px;
      height: 14px;
      background-size: contain;
      top: 15px;
      right: 10px;
    }

    h6 {
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0px;
      padding: 0px;
      padding-right: 15px;
    }


  }

  .offer_left-cations__SKwvb {

    span {
      margin-bottom: 20px !important;
    }
  }

  .d-none-btn {
    font-size: 20px !important;

  }

  .amenenities-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;

  }

  @media(max-width:767px) {
    margin-bottom: 20px;

    &.active-accordion {
      .accordion-title {
        &:after {
          background: url(../../public/assets/images/minus-icon.svg) center;
          background-size: contain;
        }
      }

      .accordion-detail {
        padding-top: 10px;
        display: block;
      }
    }

    .amenenities-content {
      justify-content: center;
    }

    .avaivality-details {
      width: 40%;

    }

    .accordion-title {
      display: block;
    }

    .accordion-detail {
      display: none;
    }
  }
}

@media(max-width:767px) {
  .d-none-btn {
    display: none !important;
  }

  .accordion-detail-lg {
    display: none;
  }
}

.btn-mobile {
  display: none;

  @media(max-width:767px) {
    display: block;
    margin-bottom: 30px;

    .btn-primary {
      display: flex;
      width: 100%;
    }
  }

}


/* map popup code */
.scrollNone {
  overflow: hidden;

  @media(max-width:767px) {
    overflow: initial;
  }
}

.map-outer-box {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  overflow: hidden;
  overflow-y: auto;
  display: none;

  &.active-map {
    display: block;

    @media(max-width:767px) {
      display: none;
    }
  }

  .map-inner-box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 120px;
    padding-bottom: 50px;
  }

  .map-img-box {
    background-color: var(--black);
    width: 100%;
    position: relative;
    max-width: 800px;
    border-radius: 5px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }

  .map-close-outer {
    position: absolute;
    width: 35px;
    height: 35px;
    padding: 5px;
    background-size: 70%;
    display: block;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 99;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 100%;
  }

  .map-close {
    position: absolute;
    width: 25px;
    height: 25px;
    background: url(../../public/assets/images/close-icon.svg) no-repeat center;
    text-indent: -99999px;
  }
}


.book-form-spacerBottom {
  margin-bottom: 15px;
}

.disableButton {
  pointer-events: none;
  opacity: .3;
  background: #ccc;
  user-select: none;
}

@media (min-width:768px) {
  .btn_booking-online {
    bottom: -20px;
  }
}


.icon-hover {
  &:hover {
    .video-play-icon {
      opacity: 0;
    }
  }
}

.video-play-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: opacity 0.3s;
  font-size: 50px;


  .anticon-play-circle {
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.9);
    background: #fff;
    overflow: hidden;
  }
}


.no-slider-content-center {
  justify-content: center;
}


.no-slider {
  flex: 0 0 auto;
  width: 33.33333333%;
  margin: 15px 0;

  @media(max-width:991px) {
    width: 50%;
  }

  @media(max-width:767px) {
    width: 100%;
  }
}


.btn-map {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 5px;
}

.text-container {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 75px;
}




/* bot style  */


#chat-circle {
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bounce 5s infinite;
  -webkit-animation: bounce 5s infinite;
  -moz-animation: bounce 5s infinite;
  -o-animation: bounce 5s infinite;
  z-index: 21474836461;

  @media(max-width:767px) {
    bottom: 10px;
    right: 10px;
  }
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.msg-avatar {
  padding: 10px;
  background: #fff;
  border-radius: 50%;
  border-radius: 245.283px;
  border: 0.5px solid rgba(128, 128, 128, 0.25);
  background: #FFF;
  box-shadow: 0px 1.962px 1.962px 0px rgba(0, 0, 0, 0.05);
}

.btn#my-btn {
  background: white;
  padding-top: 13px;
  padding-bottom: 12px;
  border-radius: 45px;
  padding-right: 40px;
  padding-left: 40px;
  color: #5865C3;
}

#chat-overlay {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: none;
}

.chat-box {
  display: none;
  position: fixed;
  left: 30px;
  bottom: 30px;
  width: 100%;
  max-width: 420px;
  max-height: 100vh;
  /*border: 1px solid #ECECEC;
  */
  z-index: 999999;
  background: #FFF;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  z-index: 21474836461;

  @media(max-width:767px) {
    width: calc(100% - 40px);
    right: 20px;
  }
}

.chat-box-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    display: flex;
    width: 24px;
    height: 24px;
    background: url(../../public/assets/images/bot/close-white.svg) center no-repeat;
    text-indent: -99999px;
    background-size: contain;
  }
}

.chat-box-header {
  height: 70px;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ECECEC;
  background: #000;
}

.chat-box-body {
  position: relative;
}

.hideChat {
  display: none !important;
}

.showChat {
  display: block !important;
}



.mc_col_custom {
  flex: 0 0 auto;
  width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 15px;
  margin-top: 15px;

  @media(max-width:991px) {
    width: 25%;
  }

  @media(max-width:767px) {
    width: 33.33333333%;
  }

  @media(max-width:480px) {
    width: 50%;
    /* .icon-b-cb{
      font-size:14px;
    } */
  }
}

.icon-b-cb {
  background: #f9f9f9;
  padding: 20px 15px;
  text-align: center;
  height: 100%;
  border-radius: 16px;
  font-size: 1rem;
  font-weight: 600;

  p {
    margin-bottom: 0px;
  }

  .icon-img {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #000;
    margin-bottom: 10px;

    img {
      max-width: 100%;
    }
  }
}


.localAreaMapGallery {
  background-color: #000;
  overflow: hidden;
  margin-top: 15px;

  .imgMap {
    width: 100%;
    position: relative;
    background-image: url(../../public/assets/images/ajax-loader.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25px;
    background-color: #f0edea;
    min-height: 400px;

    iframe {
      width: 100%;
      height: 100%;
      min-height: 300px;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }


}

.home-text-section {
  color: #ffffff;
  text-align: center;
  margin: 50px auto 10px;
  max-width: 1000px;
}

@media(min-width:768px) {
  .bg_overlay {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 25px 25px 10px;
  }
}


.faqStrip {
  background-color: #F2F2F2;
  padding: 50px;
  display: flex;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;



  .btn-faq {
    text-decoration: none;
    background-color: #373737;
    color: #ffffff;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    margin: 10px 0;
    text-transform: uppercase;
  }

  .faq-col-full {
    flex: 1 0 0%;
    padding-right: 30px;

    .h-title-2 {
      font-size: 38px;
      line-height: 1.2;
    }
  }

  .faq-col-auto {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    gap: 10px;
  }

  @media(max-width:767px) {
    flex-direction: column;
    padding: 25px;

    .faq-col-full {
      width: 100%;
      margin-bottom: 15px;
      padding-right: 0;

      .h-title-2 {
        font-size: 25px;
      }
    }


    .faq-col-auto {
      flex: 0 0 100%;
      width: 100%;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }

}


.nw-main-cb {
  @media(min-width:768) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

}

.gallery-cb {
  padding-top: 30px;

  .mc_container_inner {
    padding-bottom: 20px;
  }
}


.heading-container {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--bs-font-family);
    font-size: 20px !important;
    font-weight: 700;
    line-height: 26px;
    /* min-height: 50px; */
  }
}